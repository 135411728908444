import {View} from "../modules/View";
import gsap from 'gsap';
import {unitBrowser} from "../modules/gallery/UnitBrowser";
import {CURRENT_3D_VIEW, CURRENT_OVERLAY, UNIT_BROWSER} from "../utils/Contants";
import {state} from "../Main";
import {connectDeviceOrientation, isTouch} from "../utils/Helpers";
import {room3d} from "../modules/gallery/Room3d";

class IntroPage extends View {

	private loader: HTMLElement;
	private loadBar: HTMLElement;
	private loadBarMask: HTMLElement;
	private loadScreen: HTMLElement;
	private logoText: HTMLElement;
	private startButton: HTMLElement;
	private loaded: boolean;
	private onWindowClick: any = this._onWindowClick.bind(this);
	private startTime: any;
	private updateTime: any;
	private minTimeToView: number = 4;

	constructor(element: HTMLElement) {
		super(element);
		this.loader = this.element.querySelector('.loader');
		this.loadBar = this.element.querySelector('.bar');
		this.loadBarMask = this.element.querySelector('.mask');
		this.loadScreen = this.element.querySelector('.load-screen');
		this.logoText = document.querySelector('.main-logo .text svg');
		this.startButton = this.element.querySelector('.startButton');
		this.startTimer();
	}

	private startTimer() {
		this.startTime =  new Date();
		gsap.ticker.add(this.timer.bind(this));
	}

	private timer() {
		if (!this.loaded) {
			this.updateTime = new Date();
		}
	}

	public animateIn() {
		this.animatedIn = true;
		return new Promise(resolve => {
			gsap.to(this.loadBar, 10, {ease: 'none', backgroundPosition: '-500px, 0px', repeat: -1});
			resolve();
		});
	}

	public loadComplete() {
		if (this.loaded) {
			return;
		}

		this.loaded = true;
		this.progress = 1;
		gsap.to(this.logoText, .5, {x: -71, y: 14});

		window.removeEventListener('click', this.onWindowClick);
		window.addEventListener('click', this.onWindowClick);

		const view3d = state.getValue(CURRENT_3D_VIEW);
		const overlay = state.getValue(CURRENT_OVERLAY);
		// no deeplink
		if (!isTouch() || view3d && view3d.id == UNIT_BROWSER && overlay == null) {
			gsap.ticker.remove(this.timer);
			const secondsLoaded = Math.round((this.updateTime - this.startTime) / 1000);
			const delay = secondsLoaded < this.minTimeToView ? this.minTimeToView - secondsLoaded : 0;
			gsap.delayedCall(delay, ()=> { this.animateOut() });
		}
		else {
			gsap.to(this.startButton, .5, {autoAlpha: 1});
		}
	}

	public animateOut() {
		return super.animateOut();
	}

	public hide() {
		super.hide();
		gsap.killTweensOf(this.loadBar);
	}

	public set progress(pct: number) {
		const w = (this.loader.clientWidth * pct) + 'px';
		gsap.to(this.loadBarMask, .5, {width: w});
	}

	private _onWindowClick() {
		room3d.playSpatialMusic(true);

		if (isTouch()) {
			connectDeviceOrientation(() => {
				unitBrowser.enableDeviceOrientation();
				room3d.connectDeviceOrientation();
			});
			this.animateOut();
		}
		window.removeEventListener('click', this.onWindowClick);
	}

}

export const introPage = new IntroPage(document.querySelector('.IntroPage'))

import { View } from '../View';
import { WindowManager } from '../../utils/WindowManager';
import { clamp, getStyle, isTouch } from '../../utils/Helpers';
import gsap from 'gsap';
import { room3d } from './Room3d';
import map from '@hellomonday/map';
import { BASE_PATH } from '../../utils/Contants';
import { gallery3d } from './Gallery3d';
import { audioController, TICK } from '../../components/AudioController';

class RoomProjectMenu extends View {
	private opened: boolean = false;
	private menuItems: HTMLElement;
	private menuOpener: HTMLElement;
	private unitInfo: HTMLElement;
	private unitInfoText: HTMLElement;
	private unitLink: HTMLAnchorElement;
	private onMouseMove: any;
	private onMenuLeave: any;
	private onMenuEnter: any;
	private onMenuSelect: any;
	private onTouchMove: any;
	private onTouchStart: any;
	private onTouchEnd: any;
	private menuPaddingTop: number;
	private menuPaddingBottom: number;
	private menuBottom: number;
	private menuPositionY: number;
	private menuMaskHeight: number;
	private menuHeight: number;
	private menuItemHeight: number;
	private targetMenuY: number = 0;
	private currentMenuY: number = 0;
	private selectedIndex: number = 0;
	private lastTouchStartPointY: number = 0;
	private hoverIndex: number;
	private rendering: boolean;
	private isTouch: boolean = isTouch();

	constructor(element) {
		super(element);
		this.unitInfo = this.element.querySelector('.unit-info');
		this.unitInfoText = this.element.querySelector('.unit-info p');
		this.menuItems = this.element.querySelector('.menu-items');
		this.menuOpener = this.element.querySelector('.menu-opener');
		//this.unitLink = this.element.querySelector('.unit-link');
		this.onMouseMove = this._onMouseMove.bind(this);
		this.onMenuLeave = this._onMenuLeave.bind(this);
		this.onMenuEnter = this._onMenuEnter.bind(this);
		this.onMenuSelect = this._onMenuSelect.bind(this);
		this.onTouchMove = this._onTouchMove.bind(this);
		this.onTouchStart = this._onTouchStart.bind(this);
		this.onTouchEnd = this._onTouchEnd.bind(this);
		//
		// this.unitLink.addEventListener('click', e => {
		// 	this.select(this.selectedIndex);
		// 	this.close();
		// });
	}

	public initialize(unitData) {
		// this.element.querySelector('.unit-code').innerHTML = unitData.code;
		// this.element.querySelector('.unit-title').innerHTML = unitData.title;

		//this.unitLink.innerHTML = 'PhD Research Projects';
		//this.unitLink.setAttribute('href', BASE_PATH + unitData.slug);

		// clear old menu contents
		while (this.menuItems.firstChild) {
			this.menuItems.removeChild(this.menuItems.lastChild);
		}

		const menuItemTemp: any = this.element.querySelector('#menu-item-template');
		unitData.projects.forEach((project, index) => {
			const menuItemFrag = menuItemTemp.content.cloneNode(true);
			const menuItem = menuItemFrag.querySelector('.menu-item');
			menuItem.dataset.index = index;
			menuItem.querySelector('.project-title').innerHTML = project.title;
			menuItem.querySelector('.student-name').innerHTML = project.students.length > 1 ? 'Group Project' : project.students[0].name;
			menuItem.addEventListener('mouseenter', e => {
				e.currentTarget.classList.add('hovered');
				audioController.play(TICK);
			});
			menuItem.addEventListener('mouseleave', e => {
				e.currentTarget.classList.remove('hovered');

			});
			menuItem.addEventListener('click', e => {
				room3d.gotoPosition(index, 1.5, null, true);
			});
			this.menuItems.appendChild(menuItem);
		});

		this.selectedIndex = 0;

		if (this.isTouch) {
			this.menuOpener.removeEventListener('click', this.onMenuSelect);
			this.menuOpener.addEventListener('click', this.onMenuSelect);
		} else {
			this.menuOpener.removeEventListener('mouseenter', this.onMenuEnter);
			this.menuOpener.addEventListener('mouseenter', this.onMenuEnter);
		}
	}

	private _onTouchStart(e) {
		this.lastTouchStartPointY = e.changedTouches[0].clientY;
	}

	private _onTouchMove(e) {
		const deltaY = this.lastTouchStartPointY - e.changedTouches[0].clientY;
		this.targetMenuY -= deltaY;
		this.targetMenuY = clamp(0, this.menuHeight - this.menuMaskHeight, this.targetMenuY);
		this.lastTouchStartPointY = e.changedTouches[0].clientY;
	}

	private _onTouchEnd(e) {
		//
	}

	private _onMouseMove(e) {
		const mouseY = WindowManager.height - e.clientY - this.menuPositionY;
		const pctY = clamp(0, 1, mouseY / this.menuMaskHeight);

		if (this.menuHeight > this.menuMaskHeight) {
			this.targetMenuY = pctY * (this.menuHeight - this.menuMaskHeight);
		} else {
			this.targetMenuY = 0;
		}
	}

	private _onMenuSelect(e) {
		if (this.opened) {
			this.select(this.selectedIndex);
		} else {
			this.targetMenuY = 0;
			this.open();
		}
	}

	private _onMenuEnter(e) {
		this.open();
	}

	private _onMenuLeave(e) {
		this.select(this.selectedIndex);
		this.close();
	}

	private open() {
		if (this.opened) {
			return;
		}
		this.opened = true;
		this.rendering = true;
		this.element.classList.add('open');
		gsap.killTweensOf(this.menuItems);
		Array.from(this.menuItems.children).forEach((menuItem, index) => {
			menuItem.classList.remove('hidden');
		});
		room3d.disableLookAround();
		room3d.dim();

		if (this.isTouch) {
			this.element.addEventListener('touchstart', this.onTouchStart);
			this.element.addEventListener('touchmove', this.onTouchMove);
			this.element.addEventListener('touchend', this.onTouchEnd);
		} else {
			this.element.addEventListener('mousemove', this.onMouseMove);
			gallery3d.webglContainer.addEventListener('mouseenter', this.onMenuLeave);
		}
	}

	private updateSizes() {
		this.menuPaddingTop = parseInt(getStyle(this.menuItems, 'padding-top'), 10);
		this.menuPaddingBottom = this.unitInfoText.clientHeight + parseInt(getStyle(this.unitInfo, 'padding-bottom'), 10);
		this.menuItems.style.paddingBottom = this.menuPaddingBottom + 'px';
		this.menuBottom = parseInt(getStyle(this.menuItems, 'bottom'), 10);
		this.menuHeight = this.menuItems.clientHeight - this.menuPaddingBottom - this.menuPaddingTop;
		if (this.menuItems.children.length) {
			this.menuItemHeight = this.menuItems.children[0].clientHeight;
		}
		this.menuPositionY = this.menuPaddingBottom + this.menuBottom;
		this.menuMaskHeight = WindowManager.height - this.menuPositionY - this.menuPaddingTop;
		this.select(this.selectedIndex);
	}

	public select(index) {
		this.selectedIndex = index;

		Array.from(this.menuItems.children).forEach((menuItem, i) => {
			menuItem.classList.remove('hovered');
			menuItem.classList.remove('active');

			if (i == this.selectedIndex) {
				menuItem.classList.add('active');
			}
		});

		if (!this.opened || this.isTouch) {
			this.close();
		}
	}

	private close() {
		if (this.opened) {
			room3d.enableLookAround(1);
			room3d.undim();
		}

		Array.from(this.menuItems.children).forEach((menuItem, i) => {
			menuItem.classList.add('hidden');
			if (i == this.selectedIndex) {
				menuItem.classList.remove('hidden');
			}
		});

		this.targetMenuY = 0;
		for (let i = this.menuItems.children.length - 1; i > this.selectedIndex; i--) {
			this.targetMenuY += this.menuItems.children[i].clientHeight;
		}

		this.rendering = false;
		this.updateMenuPosition(this.targetMenuY, this.opened ? 1 : 0, () => {});
		this.element.classList.remove('open');
		this.opened = false;

		if (this.isTouch) {
			this.element.removeEventListener('touchstart', this.onTouchStart);
			this.element.removeEventListener('touchmove', this.onTouchMove);
			this.element.removeEventListener('touchend', this.onTouchEnd);
		} else {
			this.element.removeEventListener('mousemove', this.onMouseMove);
			gallery3d.webglContainer.removeEventListener('mouseenter', this.onMenuLeave);
		}
	}

	public show() {
		super.show();
		this.updateSizes();
		this.select(this.selectedIndex);
		this.close();
		this.currentMenuY = this.targetMenuY;
		this.render(true);
	}

	public resize() {
		this.updateSizes();
	}

	private updateHover() {
		if (!this.opened || this.isTouch) {
			return;
		}

		const pctY = 0.99 - clamp(0, 0.99, this.currentMenuY / (this.menuHeight - this.menuMaskHeight));
		let h = 0;
		let hIndex = 0;
		for (let i = 0; i < this.menuItems.children.length; i++) {
			h += this.menuItems.children[i].clientHeight;
			if (h / this.menuHeight > pctY) {
				hIndex = i;
				break;
			}
		}

		if (hIndex != this.hoverIndex) {
			if (this.hoverIndex > -1) {
				if (this.menuItems.children[this.hoverIndex]) {
					this.menuItems.children[this.hoverIndex].classList.remove('hovered');
				}
			}
			if (this.menuItems.children[hIndex]) {
				this.menuItems.children[hIndex].classList.add('hovered');
			}
			this.hoverIndex = hIndex;
		}
	}

	public render(force: boolean = false) {
		if (this.rendering || force) {
			this.currentMenuY += (this.targetMenuY - this.currentMenuY) / 10;
			this.updateHover();
			this.updateMenuPosition(this.currentMenuY, 0);
		}
	}

	private updateMenuPosition(positionY: number, speed: number, onComplete: any = null) {
		this.currentMenuY = positionY;
		gsap.to(this.menuItems, speed, {
			y: positionY,
			ease: 'Power4.easeOut',
			onComplete: onComplete,
			onUpdate: () => {
				// fade bottom
				Array.from(this.menuItems.children).forEach((menuItem: HTMLElement, index) => {
					const endY = this.menuHeight - (menuItem.offsetTop - this.menuPaddingTop + 30);
					const startY = endY - 20;
					const value = 1 - clamp(0, 1, map(this.currentMenuY, startY, endY, 0, 1));
					gsap.set(menuItem.children[0], { opacity: value });
				});
			}
		});
	}
}

export const roomProjectMenu = new RoomProjectMenu(document.querySelector('.RoomProjectMenu'));

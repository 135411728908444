import { ConeGeometry, Mesh, MeshBasicMaterial, MeshPhysicalMaterial, Object3D, Vector3 } from 'three';
import gsap from 'gsap';

export class Viewer {
	public projectIndex;
	public mesh;
	public offset: Vector3 = new Vector3(this.getRandomOffset() , this.getRandomOffset(), this.getRandomOffset());

	constructor(coneMesh: Mesh) {
		this.mesh = new Object3D();
		coneMesh.material = new MeshBasicMaterial({ map: (coneMesh.material as MeshPhysicalMaterial).map });
		coneMesh.rotation.y -= Math.PI / 2;
		coneMesh.visible = true;
		this.mesh.add(coneMesh);
	}

	private getRandomOffset() {
		return (Math.random() * 0.4 + .1) * (Math.random() < 0.5 ? -1 : 1);
	}
	
	public destroy() {}
}

import {EditorialModule} from "./EditorialModule";

export class SketchfabModule extends EditorialModule {

	private viewButton: HTMLElement;
	private controls: HTMLElement;

	constructor(element: HTMLElement) {
		super(element);
		this.controls = this.element.querySelector('.sketchfab-controls')
		this.viewButton = this.element.querySelector('.pill-button');
		this.viewButton.addEventListener('click', () => {
			this.initialize();
		});
	}

	initialize() {
		this.viewButton.style.display = 'none';

		// Sketchfab Viewer API: Custom Load Progress
		const version = '1.9.0';
		const iframe = this.element.querySelector('#api-frame');
		const uid = this.element.dataset.sketchfabid;//'988c46f8fc5f48e7b02737ca68b0ccf2';

		//@ts-ignore
		var client = new window.Sketchfab(version, iframe);

		var error = function() {
			console.error('Sketchfab API error');
		};

		var success = (api) => {
			const pbmodel: HTMLElement = this.element.querySelector('#pb-model');

			api.addEventListener('modelLoadProgress', (eventData) => {
				const percent = Math.floor(eventData.progress * 100);
				pbmodel.style.width = percent + '%';
				if (percent == 100) {
					this.controls.style.display = 'none';
				}
			});

			// api.addEventListener('textureLoadProgress', (eventData) => {
			// 	const percent = Math.floor(eventData.progress * 50) + 50;
			// 	pbmodel.style.width = percent + '%';
			// });

			api.addEventListener('viewerready', function() {

			});

			// autostart
			//api.start();
		};

		client.init(uid, {
			success: success,
			error: error,
			preload: 0,
			autostart: 1,
			ui_hint: 0,
			ui_infos: 0,
			ui_stop: 0,
			ui_color: 'FF0000',
			ui_controls: 0,
			ui_loading: 0,
			ui_watermark: 0,
			ui_watermark_link: 0
		});
	}
}
